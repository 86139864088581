import React from "react";
import Spacer from "../Spacer";

function WhoWeServe() {
    return (
        <section id="whoWeServe" className="module">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-9 m-auto text-center">
                        <h2>Who We Serve</h2>
                    </div>
                </div>
                <Spacer my="30px" />
                <div className="row justify-content-center">
                    <div className="col">
                        We provide <a href="https://www.incompli.com/swppp-services" target="_blank" rel="noopener noreferrer">SWPPP services in California</a> and <a href="https://www.incompli.com/swppp-consultant-texas" target="_blank" rel="noopener noreferrer">SWPPP services in the Dallas/Fort Worth metroplex area of Texas</a> for anyone that is required to maintain stormwater compliance. Our customers include property owners, developers, construction firms, and manufacturing companies to name a few, all of whom rely on our expertise and trusted guidance to support their project compliance to successful completion. If your project is in need of a SWPPP consultant in Southern California, Northern California, or the Dallas/Fort Worth metroplex area, our team is ready to assist you from start to finish.
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhoWeServe;
