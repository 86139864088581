import React from "react";
import Spacer from "../Spacer";
import IconBox from "../IconBox";

const About = () => (
    <section className="module divider-bottom">
        <div className="container">
            <div className="row">
                <div className="col-md-6 m-auto text-center">
                    <h2>What we do.</h2>
                </div>
            </div>
            <Spacer my="20px" />
            <div className="row">
                {/* <IconBox icon="ti-mobile" title="Cutting-Edge Tech">
                    We use cutting-edge technology to provide inspection reports and analytics that drive compliance costs down and maximize efficiency for our clients. Let our advanced technology help your business smoothly navigate your stormwater permit.
                </IconBox> */}
                <IconBox icon="ti-help-alt" title="What we do as your stormwater compliance and SWPPP consultants ">
                    For over ten years, Incompli has been helping property owners, developers, construction firms and manufacturing facilities adhere to stormwater regulations. Understanding the regulations and maintaining compliance is time-consuming and we, as one of the top stormwater compliance SWPPP companies, help you manage the requirements from start to finish with our team of experienced SWPPP developers and inspectors. Let our established team of professionals give you peace of mind with your stormwater compliance needs.
                </IconBox>
                <IconBox icon="ti-folder" title="SWPPP Development Services California">
                    Our stormwater development team leverages their experience and compliance expertise to generate accurate, comprehensive, and timely plans. Every SWPPP we develop is site-specific and based on the local regulatory requirements. Our <a href="https://www.incompli.com/qualified-swppp-developer">Qualified SWPPP Developers (QSDs)</a> are a valued resource for our customers as they provide guidance on each project and how to navigate site-specific needs to remain compliant with stormwater regulations. We take care of our customers and make sure they have what they need for successful project completion.
                </IconBox>
                <IconBox icon="ti-pulse" title="Stormwater SWPPP Inspection Services and Support">
                    Our team of experienced CESSWI and CISEC certified Qualified SWPPP Practitioners (QSPs) routinely monitor your project and use cutting-edge technology to provide inspection reports and analytics on your site. Additionally, our in-house Qualified SWPPP/SWP3 Developers (QSDs) provide guidance and required inspection services to meet the new Construction General Permit (CGP) QSD requirements for all projects. Rest assured knowing that our stormwater expertise will be used to identify areas that need attention and also provide guidance on how to resolve any deficiency and maintain compliance. Reports include pictures and a detailed description of suggested BMPs to help resolve the issue. As one of the leading stormwater compliance SWPPP companies, your stormwater compliance is our top priority.
                </IconBox>
                <IconBox icon="ti-blackboard" title="Stormwater SWPPP Training & Consulting Services" a href="https://www.incompli.com/swppp-consulting-and-training">
                    Leverage our stormwater compliance expertise through site-specific consultation or customized, on-site training for your key project personnel to ensure they understand inspection and project requirements. Let us support your team by educating them on key areas of focus that are specific to your site as well as protocols to follow to support compliance. We are here to help. Our training and consulting services are here to provide guidance for your project until it reaches successful completion.
                </IconBox>
                <IconBox icon="ti-blackboard" title="Drone Construction Site Mapping Services">
                    Incompli is expanding its scope by venturing into <a href="https://www.incompli.com/drone-mapping" target="_blank" rel="noopener noreferrer">drone construction aerial services</a>, mapping, and 3D modeling.Equipped with FAA-certified drone pilots, we offer comprehensive Aerial and 3D imaging solutions for your project, capturing intricate details and providing both a bird's-eye view from start to finish. These images are able to show small, impactful details of your project, provide a high level project overview, and progress over time. With our drone mapping technology, gain immediate insights into the status and progress of your construction projects. Monitor the site in real-time and make informed decisions.
                </IconBox>
            </div>
        </div>
    </section>
);

export default About;
